import React from "react"
import { graphql } from "gatsby"
import { light, dark, LinkButton, LinkButtonContainer } from "../components/styles"
import Loadable from 'react-loadable'
import styled from "styled-components"
import Img from "gatsby-image"

import YAML from 'yaml'

type Props = {
  data: any
}

export const PortFolioImg = styled(Img)`
  grid-column-start: 2;
  width: 100%;
  border-radius: 20px;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
`

export const PortfolioContentContainer = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-row-gap: 2%;
  grid-template-columns: 5% 30% 5% 55% 5%;
  grid-template-rows: 50vh 10vh repeat(3, auto);
  @media (max-width: 768px) {
    grid-template-columns: 5% 90% 5%;
    grid-template-rows: 40vh 50vh repeat(4, auto);
  }
  margin-top: 5vh;
  margin-bottom: 10vh;
  
`
const EditorContainer = styled.div`
  align-self: start;
  justify-self: start;
  width: 100%;
  height: 100%;
  grid-column-start: 4;
  grid-row-start: 1;
  @media (max-width: 768px) {
    grid-row-start: 2;
    justify-self: center;
    grid-column-start: 2;
  }
  border-radius: 20px;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
`

const TitleArea = styled.div`
   grid-row-start: 3;
   grid-column-start: 2;
   text-overflow: ellipsis;
   width: 100%;
  @media (min-width: 769px) {
   grid-row-start: 2 ;
   grid-column-end: span 3;
  }
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid ${dark};
  
`
const Title = styled.h1`
  justify-self: start;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
  align-self: center;
  font-size: 2rem;
  @media(min-width: 769px) {
    align-self: start;
    font-size: 3rem;
  }
`
const ContentSummary = styled.h2`
  width: 100%;
   grid-row-start: 4;
   grid-column-start: 2;
   font-size: 1.2rem;
  @media (min-width: 769px) {
    font-size: 1.5rem;
    grid-row-start: 3;
    grid-column-end: span 3;
    margin-top: 30px;
  }
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
`
const ContentDetails = styled.p`
  width: 100%;
  grid-column-start: 2;
  grid-row-start: 5;
  @media (min-width: 769px) {
    grid-row-start: 4;
    grid-column-end: span 3;
  }
  font-size: 1.1rem;
  color: ${dark};
  @media(prefers-color-scheme: dark) {
    color: ${light};
  }
`

const LoadableEditor = Loadable({
  loader: () => import('../components/elements/editor'),
  loading() {
    return <div>Loading...</div>
  }
});



const PortfolioTemplate: React.FC<Props> = ({data}) => {
  const {
    yaml,
    yaml: {
      project: {
        title,
        summary,
        details,
        links: {
          github,
          demo
        }
      }
    }
  } = data

  return (
    <PortfolioContentContainer>
      <PortFolioImg fluid={data.img.childImageSharp.fluid}/>
      <EditorContainer>
        <LoadableEditor name={title} height="100%" width="100%" val={YAML.stringify(yaml)}/>
      </EditorContainer>
      <TitleArea>
        <Title>{title}</Title>
      </TitleArea>
      <ContentSummary>
        {summary}
      </ContentSummary>
      <ContentDetails>
        {details}
      </ContentDetails>
      <LinkButtonContainer>
        <LinkButton target="_blank" href={github}>github</LinkButton>
        <LinkButton target="_blank" href={demo}>demo</LinkButton>
      </LinkButtonContainer>
    </PortfolioContentContainer>
  )
}
export const pageQuery = graphql`
    query($path: String!, $img: String!) {
        yaml(project: {path: {eq: $path}}) {
            project {
                title
                type
                summary
                technologies
                environments
                features
                links {
                    github
                    demo
                } 
                details
                img
            }
        }
        img:  file(relativePath: { eq: $img }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`


export default PortfolioTemplate
